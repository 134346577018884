// @import "normalize";

body {
    background: lightgrey;
}

h1 {
    color: rgba(0, 0, 0, .7);
    text-align: center;
}
h2 {
    color: rgb(0, 0, 0);
    text-align: center;
}
p {
    text-align: center;
}
img {
    width: 100%
}

#container {
    background: white;
    margin: 80px 40px 0;
}

#container .item {
    margin: 40px;
}

#container .item > div {
    display: inline-block;
    padding: 40px;
}

#container span {
    color: red;
    text-transform: uppercase;
    font-weight: bold;
}
#content {
    background: white;
     margin: 80px 40px 0;
    padding: 10px;
    display: flex;
    flex-direction: row;
}

#content .item {
    background: lightgrey;
    margin: 10px;
    padding-bottom: 10px
}

#content .item > div {
    padding: 0 10px;
}
#content2 {
    background: white;
     margin: 80px 40px 0;
}

#content2 .item {
}

#content2 .item > div {
    padding: 10px;
}
strong {
    color: teal;
}

